<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <span>Audits ></span>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des audits
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="table_action">
        <div class="div_left"></div>
        <div class="div_left">
          <!-- <a class="btn btn-blue"
             :href="baseUrl + marches.url"
             download>
            Exporter
          </a> -->
        </div>
      </div>
      <div class="tab_holder">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <th scope="col"
                  class="text-left th-blue">
                Description
              </th>
              <th scope="col"
                  class="text-left th-blue">
                Opération
              </th>
              <th scope="col"
                  class="text-center th-blue">
                Table cible
              </th>
              <th scope="col"
                  class="text-left th-blue">
                Utilisateur
              </th>
              <th scope="col"
                  class="text-left th-blue">
                Date
              </th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr>
              <td class="text-center"
                  colspan="7">
                Chargement...
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="long != 0">
            <tr v-for="(audit,akey) in audits.donnees"
                :key="akey"
                @click="launchDetails(audit.id)">
              <td>{{audit.description}}</td>
              <td>{{audit.operation}}</td>
              <td>{{audit.table_cible}}</td>
              <td>{{audit.utilisateur[0].email}}</td>
              <td>{{audit.date}}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center"
                  colspan="7">
                Aucun marché enregistré
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row pagination">
        <div v-if="audits.pagination"
             class="col-md-2 mx-auto text-center">
          <span v-if="audits.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="pIndex--, paging(audits.pagination.precedent)"> &#60; </span>
          {{ pIndex }}
          <span v-if="audits.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="pIndex++, paging(audits.pagination.suivant)"> &#62; </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.48em;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "Audits",
  data: () => ({
    user: "",
    baseUrl: process.env.VUE_APP_UPLOAD,
    pIndex: 1,
    long: "",
    loaded: false,
    goodUrl: ""
  }),
  watch: {
    audits() {
      if (this.audits.donnees) {
        this.loaded = true
        this.long = this.audits.donnees.length
        this.audits.donnees.forEach(audit => {
          if (audit.date.includes("T")) {
            audit.date = audit.date.split("T")
            audit.date = audit.date[0]
          }
        })
      }
    },

  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.Audits()
  },
  computed: {
    ...mapGetters(["audits", "auditsErrors"])
  },
  methods: {
    ...mapActions(["Audits","pageAudits"]),
    ...mapMutations(["setAudits","setAuditsErrors"]),
    onFormChange() {
      this.form.validate()
    },
    launchDetails(id) {
      this.$router.push({ name: "DetailsAudit", params: { id: id } })
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      this.pageAudits(url)
    },
  }
}
</script>
